import styled from 'styled-components';
import vars from '../lib/styled';

export default styled.div`
    ul {
        margin-bottom: 1rem;
        padding-left: 2rem;

        &:not(:last-of-type) {
            border-bottom: 2px solid ${vars.muted};
            padding-bottom: 1rem;
        }
    }

    h3,
    h4 {
        font-size: 1em;
    }

    li,
    p {
        margin-bottom: .5rem;
        font-size: 0.833rem;
    }

    h3:first-of-type,
    h4:first-of-type {
        margin-top: 1rem; 
    }
`;
