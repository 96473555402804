import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Collapse, Tabs, Tab } from '@material-ui/core'
import Button from './Button'
import { useLang, useLangCode } from './Locale'
import { get } from 'lodash'

const tableBody = [
  ['Total Fat', 'Less than', '65g', '80g'],
  ['Sat Fat', 'Less than', '20g', '25g'],
  ['Cholesterol', 'Less than', '300mg', '300mg'],
  ['Sodium', 'Less than', '2,400mg', '2,400mg'],
  ['Total Carbohydrate', '', '300g', '375g'],
  ['Dietary Fiber', '', '25g', '30g'],
]

const shouldNested = ['Sat Fat', 'Dietary Fiber']

const mutateNutritionalInformationFromUs = (item) => {
  const keys = {
    per: 'servingSize',
    vitaminDDv: 'vitsaminDDv',
    carbohydrateAmount: 'totalCarbohydrateAmount',
    carbohydrateDv: 'totalCarbohydrateDv',
    carbohydrateFiberAmount: 'dietaryFiberAmount',
    carbohydrateFiberDv: 'dietaryAmountDv',
    carbohydrateSugarAmount: 'sugarsAmount',
    carbohydrateAddedSugarAmount: 'addedSugarsAmount',
    carbohydrateAddedSugarDv: 'addedSugarsDv',
    fatSaturatedAmount: 'saturatedFatAmount',
    fatSaturatedDv: 'saturatedFatDv',
    fatAmount: 'totalFatAmount',
    fatDv: 'totalFatDv',
    transSaturatedAmount: 'transFatAmount',
  }

  Object.entries(keys).forEach(([ca, us]) => {
    if (item[ca]) return
    if (item[us]) item[ca] = item[us]
  })

  if (item.caloriesFromFat)
    item.caloriesDv = `Calories from Fat ${item.caloriesFromFat}`
}

const Table = styled.table`
  border-collapse: collapse;
  font-size: 0.833rem;
  width: 100%;

  caption {
    font-size: 1rem;
    padding: 1rem;
    text-align: left;
  }

  tr:not(:first-of-type) {
    border-top: 1px solid;
  }

  td:last-of-type: {
    border-bottom: 1px solid;
  }

  td,
  th {
    padding: 0.25rem 0;

    &:first-of-type {
      text-align: left;
    }
    &:last-of-type {
      text-align: right;adl;jkf
    }
  }

  tbody {
    border-bottom: 1px solid;
    border-top: 5px solid;
  }

  tfoot {
    font-size: 0.77rem;

    & > tr:first-child td {
      padding: 0.5rem;
    }

    & > tr:not(:last-of-type) {
      border-bottom: 1px solid;
    }
  }
`

const NestedTable = styled.table`
  padding: 0;
  width: 100%;

  tr {
    border: 0;
  }

  td {
    padding: 0 0 0 0.5rem;
  }
`

const GuideTable = styled(NestedTable)`
  margin-top: 10px;
  border-spacing: 0;
  th {
    border-bottom: 1px solid;
    font-weight: normal;
  }

  td {
    padding: 0;
  }
`

const Figure = styled.figure`
  margin: 0;
  max-width: 100%;
  overflow: auto;
  padding: 0;
`

const renderNutritionRow = (props) => {
  const Row = ({ attribute, darken, supressDv }) => {
    const t = useLang()
    const getAttribute = (suffix) => props[`${attribute}${suffix}`]
    const amount = getAttribute('Amount') || props[attribute]
    const dv = getAttribute('Dv')

    if (!dv && !amount) return null

    return (
      <tr style={{ borderBottom: darken ? `3px solid` : undefined }}>
        <td>
          {t(attribute)} {amount}
        </td>
        {dv && !supressDv ? <td>{dv}</td> : <td aria-hidden />}
      </tr>
    )
  }

  Row.propTypes = {
    attribute: PropTypes.string.isRequired,
    supressDv: PropTypes.bool,
    darken: PropTypes.bool,
  }

  Row.defaultProps = {
    supressDv: false,
    darken: false,
  }

  return Row
}

const NutritionInformationNested = ({ children }) => (
  <tr style={{ border: 0 }}>
    <td colSpan="2">
      <NestedTable>{children}</NestedTable>
    </td>
  </tr>
)

const NutritionInformationNestedWithSum = ({ children, sum }) => (
  <NutritionInformationNested>
    <tr>
      <td>
        <table>{children}</table>
      </td>
      <td>{sum}</td>
    </tr>
  </NutritionInformationNested>
)

const NutritionFooter = ({ children, noBorder }) => (
  <tr
    style={{
      borderTop: noBorder ? 'none' : '1px solid',
    }}
  >
    <td
      colSpan="2"
      style={{
        fontStyle: 'italic',
        textAlign: 'left',
      }}
    >
      {children}
    </td>
  </tr>
)

const GuideLineTable = () => (
  <>
    <tr>
      <td colSpan={2}>
        <GuideTable>
          <thead>
            <tr>
              <th colSpan={2}>Calories:</th>
              <th>2,000</th>
              <th>2,500</th>
            </tr>
          </thead>
          <tbody>
            {tableBody.map((x) => (
              <tr key={x[0]}>
                {x.map((y, i) => (
                  <td
                    key={x + i}
                    style={{
                      paddingLeft: shouldNested.includes(y) ? '20px' : 0,
                    }}
                    aria-hidden={y.length === 0}
                  >
                    {y}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </GuideTable>
      </td>
    </tr>
    <NutritionFooter noBorder>Calories per gram</NutritionFooter>
    <NutritionFooter>Fat 9 • Carbohydrate 4 • Protein 4</NutritionFooter>
  </>
)

const NutritionInformation = ({ data }) => {
  const t = useLang()
  const code = useLangCode()
  const [tab, setTabs] = React.useState(0)
  const [visible, setVisibility] = React.useState(false)
  const isUs = code === 'en-us'

  const handleTab = (e, newState) => setTabs(newState)
  const id = (str) =>
    typeof str === 'string' ? str.toLowerCase().replace(/\s/gi, '-') : ''

  if (!Array.isArray(data)) return null

  data.forEach((item) => {
    Object.assign(item, {
      id: id(item.productName),
    })
  })

  const toggle = () => setVisibility(!visible)

  return (
    <>
      <Button
        aria-controls="tables"
        aria-expanded={visible}
        primary
        onClick={toggle}
      >
        {visible ? t('showLess') : t('showMore')}
      </Button>
      <Collapse in={visible}>
        <Figure id="tables">
          <h2
            style={{
              fontSize: '1rem',
              marginBottom: '1rem',
              marginTop: '1.5rem',
            }}
          >
            {t('nutrition')}:
          </h2>
          {data.length > 1 && (
            <Tabs aria-label={t('nutrition')} onChange={handleTab} value={tab}>
              {data.map((item) => (
                <Tab
                  role="tab"
                  key={`${item.id}-tab`}
                  id={`${item.id}-tab`}
                  aria-controls={item.id}
                  tabIndex={0}
                  label={item.productName || t('product')}
                  style={{
                    fontSize: '.833rem',
                    textTransform: 'none',
                  }}
                />
              ))}
            </Tabs>
          )}
          {data.map((item, i) => {
            mutateNutritionalInformationFromUs(item)
            const Row = renderNutritionRow(item)

            const footnote =
              item.includeDefaultFootnotes || isUs
                ? get(item, 'footnotes.footnotes')
                : t('footnote')

            return (
              <div
                role="tabpanel"
                aria-labelledby={`${item.id}-tab`}
                id={item.id}
                key={item.id}
                tab={tab}
                style={{
                  display: i === tab ? 'block' : 'none',
                  marginTop: '1rem',
                }}
              >
                {tab === i && (
                  <Table style={{ borderCollapse: 'none' }}>
                    <thead>
                      <tr>
                        <th colSpan="2" style={{ textAlign: 'left' }}>
                          {t('per')} {item.per}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {t('amountPerServing') && (
                        <tr>
                          <td
                            colSpan="2"
                            style={{ textAlign: 'left', fontWeight: 'bold' }}
                          >
                            {t('amountPerServing')}
                          </td>
                        </tr>
                      )}
                      <Row darken={!isUs} attribute="calories" />
                      <tr style={{ borderTop: `3px solid` }}>
                        <td
                          colSpan="2"
                          style={{ textAlign: 'right', fontWeight: 'bold' }}
                        >
                          % {t('dailyValue')} *
                        </td>
                      </tr>

                      <Row attribute="fat" />
                      {item.fatAmount && (
                        <NutritionInformationNestedWithSum
                          sum={item.fatSaturatedDv}
                        >
                          <Row supressDv attribute="fatSaturated" />
                          <Row supressDv attribute="transSaturated" />
                        </NutritionInformationNestedWithSum>
                      )}
                      <Row attribute="carbohydrate" />
                      <NutritionInformationNested>
                        <Row attribute="carbohydrateFiber" />
                        <Row attribute="carbohydrateSugar" />
                        <NutritionInformationNested>
                          <Row attribute="carbohydrateAddedSugar" />
                        </NutritionInformationNested>
                      </NutritionInformationNested>
                      <Row attribute="protein" />
                      <Row attribute="cholesterol" />
                      <Row attribute="sodium" />
                    </tbody>
                    <tbody>
                      <Row attribute="potassium" />
                      <Row attribute="calcium" />
                      <Row attribute="iron" />
                      <Row attribute="vitaminA" />
                      <Row attribute="vitaminC" />
                    </tbody>
                    <tfoot>
                      {item.disclaimerMultiline && (
                        <NutritionFooter>
                          {item.disclaimerMultiline.disclaimerMultiline}
                        </NutritionFooter>
                      )}
                      {footnote && (
                        <NutritionFooter>{footnote}</NutritionFooter>
                      )}
                      {item.includeRecommendedNutritionGuidelines && (
                        <GuideLineTable />
                      )}
                    </tfoot>
                  </Table>
                )}
              </div>
            )
          })}
        </Figure>
      </Collapse>
    </>
  )
}

NutritionInformation.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      productName: PropTypes.string,
      per: PropTypes.string,
      calories: PropTypes.string,
      fatAmount: PropTypes.string,
      fatDv: PropTypes.string,
      fatSaturatedAmount: PropTypes.string,
      fatSaturatedDv: PropTypes.string,
      transSaturatedAmount: PropTypes.string,
      cholesterolAmount: PropTypes.string,
      cholesterolDv: PropTypes.string,
      sodiumAmount: PropTypes.string,
      sodiumDv: PropTypes.string,
      carbohydrateAmount: PropTypes.string,
      carbohydrateDv: PropTypes.string,
      carbohydrateFiberAmount: PropTypes.string,
      carbohydrateFiberDv: PropTypes.string,
      carbohydrateSugarAmount: PropTypes.string,
      carbohydrateSugarDv: PropTypes.string,
      proteinAmount: PropTypes.string,
      vitaminADv: PropTypes.string,
      vitaminCDv: PropTypes.string,
      calciumDv: PropTypes.string,
      potassiumDv: PropTypes.string,
      ironDv: PropTypes.string,
      disclaimerMultiline: PropTypes.shape({
        disclaimerMultiline: PropTypes.string,
      }),
      footnotes: PropTypes.shape({
        footnotes: PropTypes.string,
      }),
    }),
  ),
}

export default NutritionInformation
