/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Image from 'gatsby-image'
import lang from '../../static/lang.json'
import App from '../components/App'
import { Header, HeaderHeadline, HeaderDivider } from '../components/Hero'
import Main from '../components/Main'
import BreadCrumbs from '../components/Breadcrumbs'
import Grid, { Half } from '../components/Grid'
import { generateSlug, capitalizeWord } from '../helpers/slug'
import Wrapper from '../components/Wrapper'
import { PanelContainer } from '../components/Panel'
import ListDivider from '../components/ListDivider'
import CardRecipe from '../components/CardRecipe'
import CallToAction from '../components/CallToAction'
import { HrefLangMeta } from '../components/Locale'
import SEO from '../components/SEO.jsx'
import vars from '../lib/styled'
import { useLang } from '../components/Locale'
import NutritionInformation from '../components/NutritionInformation'
import CanonicalLink from '../components/CanonicalLink'
import { compact, get, map, last, isFunction } from 'lodash'
import ReactMarkdown from 'react-markdown'

const ItalicHandling = styled.div`
  i {
    color: #a81a63;
    font-style: initial;
  }
`

const arrayAssembly = (data) => {
  const store = compact([data].flat())

  return {
    add: (xs, fn) =>
      xs ? store.push(isFunction(fn) ? fn(xs, store) : xs) : undefined,

    get: () => store,
  }
}

const negate = (fn) => (xs) => !fn(xs)

const forEachProduct = (fn) => (xs, options) =>
  map(compact(get(xs, 'product')), (product) => fn(product, options))

const getDuplicateProductLinks = forEachProduct((product, { root, title }) => {
  const aa = arrayAssembly(root)

  ;[get(product, 'category.title'), product.title, title].forEach(aa.add)

  return generateSlug(aa.get().join('/'))
})

const getCrumbs = forEachProduct(({ title, category }) => {
  const aa = arrayAssembly()

  ;[get(category, 'title'), title].forEach((xs) =>
    aa.add(xs, (label, store) => ({
      href: map(store, 'href').concat(generateSlug(label)).join('/'),
      label,
    })),
  )

  return aa.get()
})

const reformatHeaders = (obj) => {
  if (!obj || !('childContentfulRichText' in obj)) return null
  const stringify = JSON.stringify(obj.childContentfulRichText.html)
  return stringify.replace(/h4/g, 'h3')
}

const Variant = ({ data, path }) => {
  const t = useLang()
  const { contentfulCallToAction, contentfulVariant, translation } = data
  const locale = contentfulVariant.node_locale.toLowerCase()
  const productsLabel = lang.products[locale]
  const cookingInstructions = reformatHeaders(
    data.contentfulVariant.instructions,
  )

  const matchesPath = (xs) => String(path).includes(xs)

  const crumbs = [{ label: capitalizeWord(productsLabel) }]
    .concat(
      getCrumbs(contentfulVariant).find((xs) =>
        matchesPath(get(last(xs), 'href')),
      ) || [],
    )
    .map((xs) => {
      xs.href = `/${generateSlug(productsLabel)}/${xs.href || ''}`
      return xs
    })

  const duplicates = getDuplicateProductLinks(contentfulVariant, {
    title: contentfulVariant.title,
    root: productsLabel,
  }).filter(negate(matchesPath))

  return (
    <App>
      <SEO {...contentfulVariant} />
      <CanonicalLink canonical={data.contentfulVariant.canonical} />
      {map(duplicates, (dup) => (
        <CanonicalLink key={dup} canonical={dup} />
      ))}
      {translation && (
        <HrefLangMeta
          {...translation}
          product={translation.product && translation.product[0]}
          category={translation.product && translation.product[0].category}
          sub="products"
        />
      )}
      <div style={{ backgroundColor: vars.contrast }}>
        <Header>
          <Grid>
            <Half>
              <HeaderHeadline style={{ marginBottom: 0 }}>
                {contentfulVariant.title.split('(').map((sentence, i) => {
                  if (i === 0) return sentence
                  return <div style={{ fontSize: '60%' }}>{`(${sentence}`}</div>
                })}
              </HeaderHeadline>
              <span>
                {`${t('servingSize')}: ${data.contentfulVariant.serving}`}
              </span>
              <HeaderDivider />
              <BreadCrumbs
                title={contentfulVariant.title}
                breadcrumbs={crumbs}
              />
              {data.contentfulVariant.ingredientsPhoto && (
                <Image
                  fluid={data.contentfulVariant.ingredientsPhoto.fluid}
                  alt={data.contentfulVariant.ingredientsPhoto.title}
                  style={{
                    marginTop: '2rem',
                    width: 150,
                  }}
                />
              )}
              {data.contentfulVariant.ingredients && (
                <div style={{ marginTop: '2rem' }}>
                  <h2
                    style={{
                      display: 'inline',
                      fontSize: '1rem',
                    }}
                  >
                    {t('ingredients')}:
                  </h2>
                  <p>
                    <i>
                      <ReactMarkdown
                        // eslint-disable-next-line
                        children={
                          data.contentfulVariant.ingredients.ingredients
                        }
                      />
                    </i>
                  </p>
                </div>
              )}
              <NutritionInformation
                data={data.contentfulVariant.nutritionTables}
              />
            </Half>
            <Half>
              {data.contentfulVariant.photo && (
                <Image
                  fluid={data.contentfulVariant.photo.fluid}
                  alt={data.contentfulVariant.photo.title}
                  style={{ maxWidth: 500, margin: '0 auto' }}
                />
              )}
            </Half>
          </Grid>
        </Header>
      </div>
      <Wrapper>
        <Main>
          <Grid
            style={{
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            {cookingInstructions && (
              <PanelContainer style={{ flex: '1' }}>
                <h2>{lang.cookingInstructions[locale]}</h2>
                <ItalicHandling>
                  <ListDivider
                    dangerouslySetInnerHTML={{
                      __html: JSON.parse(cookingInstructions),
                    }}
                  />
                </ItalicHandling>
              </PanelContainer>
            )}

            {contentfulVariant.recipes && (
              <CardRecipe
                featured
                title={contentfulVariant.recipes[0].title}
                image={contentfulVariant.recipes[0].photo}
                url={generateSlug(
                  [
                    locale,
                    lang.recipes[locale],
                    contentfulVariant.recipes[0].title,
                  ].join('/'),
                )}
                button={lang.recipesView[locale]}
                serves={contentfulVariant.recipes[0].serves}
                time={contentfulVariant.recipes[0].time}
                body={null}
              />
            )}
          </Grid>
          {contentfulCallToAction && (
            <CallToAction {...contentfulCallToAction} />
          )}
        </Main>
      </Wrapper>
    </App>
  )
}

export default Variant

export const query = graphql`
  fragment VariantDetail on ContentfulVariant {
    node_locale
    title
    product {
      title
      category {
        title
      }
    }
  }

  query getProductVariantByID($contentful_id: String, $node_locale: String) {
    translation: contentfulVariant(
      contentful_id: { eq: $contentful_id }
      node_locale: { ne: $node_locale }
    ) {
      ...VariantDetail
    }

    contentfulVariant(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $node_locale }
    ) {
      ...VariantDetail
      canonical
      serving
      nutrition {
        title
        fluid(maxWidth: 400) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      photo {
        title
        fluid(maxWidth: 800) {
          ...GatsbyContentfulFluid
        }
      }
      ingredientsPhoto {
        title
        fluid(maxWidth: 400) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      ingredients {
        ingredients
      }
      instructions {
        childContentfulRichText {
          html
        }
      }
      recipes {
        title
        serves
        time
        photo {
          title
          fluid(quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
      }
      nutritionTables {
        ## CA
        productName
        per
        carbohydrateAmount
        carbohydrateDv: carbohydrate
        carbohydrateFiberAmount
        carbohydrateFiberDv
        carbohydrateSugarAmount
        carbohydrateSugarDv
        fatSaturatedAmount
        fatSaturatedDv

        # US
        cholesterolDv
        servingSize
        servingAmount
        caloriesFromFat
        vitaminDAmount
        vitsaminDDv
        totalCarbohydrateAmount
        totalCarbohydrateDv
        dietaryFiberAmount
        dietaryAmountDv
        sugarsAmount
        addedSugarsDv
        addedSugarsAmount
        saturatedFatAmount
        saturatedFatDv
        calories
        totalFatAmount
        saturatedFatDv
        transFatAmount
        fatAmount: fatAmout
        totalFatDv
        includeRecommendedNutritionGuidelines

        transSaturatedAmount
        cholesterolAmount
        sodiumAmount
        sodiumDv
        proteinAmount
        vitaminADv
        vitaminCDv
        calciumAmount
        calciumDv
        potassiumAmount
        potassiumDv
        ironAmount
        ironDv
        disclaimerMultiline {
          disclaimerMultiline
        }
        footnotes {
          footnotes
        }
      }
    }

    contentfulCallToAction(
      contentful_id: {
        in: ["55GMmqIlsrGev5TDipNslJ", "6LluopkKDe3iW03Ky24JT2"]
      }
      node_locale: { eq: $node_locale }
    ) {
      title
      subtitle
      body {
        childContentfulRichText {
          html
        }
      }
      button: buttonText
      url
    }
  }
`
